import React from 'react';
import { Link } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo';
import Section from '../components/Section/Section';

const PlatformFeatures = () => {
  return (
    <Layout>
      <Seo
        title="Platform Features | Bridge"
        description="Improve the patient experience with a single platform that offers a robust suite of self-service features for optimal convenience."
      />
      <Section variant="hero">
        <Container>
          <Row>
            <Col>
              <h1>Platform Features</h1>
              <p>
                Improve the patient experience with a single platform that
                offers a robust suite of self-service features for optimal
                convenience.
              </p>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className="mb-4">
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/patient-appointment-reminder-solution/"
                >
                  Appointment Reminders
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/medical-scheduling-software/"
                >
                  Appointment Scheduling
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/patient-broadcast-mass-messaging/"
                >
                  Broadcast Messaging
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/patient-bill-pay-software/"
                >
                  Bill Pay
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/patient-forms-self-assessment-outcomes/"
                >
                  Forms & Assessments
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/lab-results-patient-portal/"
                >
                  Lab Results
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/online-medical-record-personal-health-record-phr-portal/"
                >
                  Medical Recods
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/personalized-patient-education/"
                >
                  Patient Education
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/patient-intake-software/"
                >
                  Patient Intake
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/patient-messaging-software-solution/"
                >
                  Patient Messaging
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/patient-referral-software/"
                >
                  Patient Referal
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/caregiver-proxy-patient-portal-access/"
                >
                  Proxy Access
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/patient-recall-care-gap-messaging/"
                >
                  Recall & Care Gaps
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/remote-patient-monitoring/"
                >
                  Remote Patient Monitoring
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/revenue-cycle-management/"
                >
                  Revenue Cycle Management
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/prescription-rx-refill-renewal-request/"
                >
                  Rx Refill
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/patient-portal-self-registration-login/"
                >
                  Self Registration
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/patient-satisfaction-outcomes-surveys/"
                >
                  Surveys
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/healthcare-task-management/"
                >
                  Task Management
                </Link>
              </div>
            </Col>
            <Col lg={3}>
              <div className="rounded-3 p-3 shadow-lg mb-3 c-logo-box">
                <Link
                  as="a"
                  className="text-secondary"
                  to="/patient-chat-texting-chatbot/"
                >
                  Texting & Chat
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default PlatformFeatures;
